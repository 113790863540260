type Route = "home" | "booking-confirmed" | "enquiry-submitted";
let lastSelectedRoute: Route;

export function applyTemplateForRoute(route: Route) {
  const contentDisplayEl = document.getElementById("current-view");
  const template = document.getElementById(
    `template-${route}`
  ) as HTMLTemplateElement;

  if (lastSelectedRoute) document.body.classList.remove(lastSelectedRoute);

  document.body.classList.add(route);

  const templateClone = document.importNode(template.content, true);
  contentDisplayEl.innerHTML = "";
  contentDisplayEl.appendChild(templateClone);

  lastSelectedRoute = route;
}
