export default function initContactForm({
  onSubmitted,
}: {
  onSubmitted: (result: { error: Error } | { firstName: string }) => void;
}) {
  const form = document.getElementById("contact-form") as HTMLFormElement;
  const firstNameEl = document.getElementById("first-name") as HTMLInputElement;
  const lastNameEl = document.getElementById("last-name") as HTMLInputElement;
  const emailEl = document.getElementById("email") as HTMLInputElement;
  const practiceEl = document.getElementById("practice") as HTMLInputElement;
  const commentEl = document.getElementById("comment") as HTMLTextAreaElement;

  form.addEventListener("submit", function (event) {
    event.preventDefault();

    const placeholder = "visitor_did_not_supply";
    const formData = {
      firstname: firstNameEl.value.trim() || placeholder,
      lastname: lastNameEl.value.trim() || placeholder,
      email: emailEl.value.trim(),
      company: practiceEl.value.trim() || placeholder,
      address: commentEl.value.trim() || placeholder,
    };

    console.log(formData);

    fetch("https://start.chiralcloud.com/api/addContact", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        onSubmitted({ firstName: formData.firstname });
      })
      .catch((error) => {
        onSubmitted({ error });
      });
  });
}
