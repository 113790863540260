import "../lib/calendly";

export function initCalendlyWidget() {
  window.Calendly.initInlineWidget({
    url: "https://calendly.com/dominic-102/30min?hide_event_type_details=1",
    parentElement: document.getElementById("calendly-container"),
    prefill: {},
    utm: {},
  });
}

export function initVisitorCreatedBooking(args: { onCreatedBooking(): void }) {
  window.addEventListener("message", function (event) {
    if (visitorCreatedCalendlyBooking(event)) args.onCreatedBooking();
  });

  const visitorCreatedCalendlyBooking = (
    event: MessageEvent<{ event: string }>
  ) =>
    isCalendlyEvent(event) && event.data.event === "calendly.event_scheduled";

  const isCalendlyEvent = (
    event: MessageEvent<{ event: string }>
  ): event is MessageEvent<{ event: CalendlyEventName }> =>
    event.data?.event?.indexOf("calendly") === 0;
}

type CalendlyEventName =
  | "calendly.profile_page_viewed"
  | "calendly.event_type_viewed"
  | "calendly.date_and_time_selected"
  | "calendly.event_scheduled";
