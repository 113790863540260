import { initCalendlyWidget, initVisitorCreatedBooking } from "./calendlySetup";
import initContactForm from "./contactForm";
import { applyTemplateForRoute } from "./navigationSetup";

applyTemplateForRoute("home");

initCalendlyWidget();

initVisitorCreatedBooking({
  onCreatedBooking() {
    applyTemplateForRoute("booking-confirmed");
  },
});

initContactForm({
  onSubmitted(result) {
    if ("error" in result) {
      return alert(
        "Unfortuntely, your comment was unable to be submitted. Please try again."
      );
    }

    applyTemplateForRoute("enquiry-submitted");

    if (result.firstName) {
      document.getElementById(
        "enquirer-first-name"
      ).innerHTML = `, ${result.firstName}`;
    }
  },
});
